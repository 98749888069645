import React, {useEffect} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import './HowToBuy.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dataProvider from "../../provider/api-interface";

function HowToBuy({product, wishlist, boutique, selectedVariation}: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    //console.log(wishlist, 'wishlist gifter')

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    if(wishlist !== null && boutique !== null && product !== null && selectedVariation !== null) {
        let variationSku = product.variations !== undefined ? product.variations[selectedVariation].sku : product.product_code;
        let productName = product.title;

        let phoneNumber = wishlist.sales_assistant.phone_number;
        let whatsappText = t('sendWhatsappMessagePart1')+wishlist.name+': '+productName+' '+variationSku;

        let whatsappSection = <span></span>;
        if(phoneNumber !== null && phoneNumber !== undefined && phoneNumber !== '') {
            let textEncoded = encodeURIComponent(whatsappText);
            let sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
            whatsappSection = <Link to={`https://wa.me/${sanitizedPhoneNumber}/?text=${textEncoded}`}>
                <div className="AddressButton">
                    <button id="shopInStore-button" className="Fgift-button"><WhatsappIcon
                        fontSize={'small'}></WhatsappIcon> {t('bookAppointmentButton')}</button>
                </div>
            </Link>;
        }

        let diorLocale = 'fr_fr';
        if(parseInt(boutique.dior_locale_id) !== 1) {
            if(parseInt(boutique.dior_locale_id) === 2) {
                diorLocale = 'en_gb';
            } else if(parseInt(boutique.dior_locale_id) === 3) {
                diorLocale = 'en_us';
            }
        }

        let diorProductUrl = `https://www.dior.com/${diorLocale}/fashion/products/${variationSku}?utm_source=4Gift&utm_medium=${wishlist.list_code}`

        if(parseInt(boutique.dior_locale_id) === 4) {
            diorProductUrl = product.link_redirect !== null ? product.link_redirect : `https://www.dior.com/en_ae/fashion/products/${variationSku}?utm_source=4Gift&utm_medium=${wishlist.list_code}`;
        }

        return (
            <div className="HowToBuyContainer">
                <h1>{t('howToBuyH1')}</h1>
                <div className="HowToBuy">
                    <Accordion style={{width: '100%', padding: '10px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className="HowToBuySectionTitle">{t('bookAppointment')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{t('howToBuyP1')}</p>
                            <div className="AddressName">
                                <p>{boutique.name}</p>
                            </div>
                            <div className="WishlistAddress">
                                <FmdGoodIcon/>
                                <p>{boutique.address_line_1}, {boutique.zip_code} {boutique.city}</p>
                            </div>
                            {whatsappSection}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{width: '100%', padding: '10px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="HowToBuySectionTitle">{t('contactUs')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{t('howToBuyP2')}</p>
                            <div className="CallCenterButton">
                                <Link id="shopRemotely-button" to={`tel:${boutique.phone_number}`} className="Fgift-button" >{t('callCenterButton')}</Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {product.purchasable_online === 1 && (
                        <Accordion style={{width: '100%', padding: '10px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography className="HowToBuySectionTitle">{t('purchaseOnDior')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{t('purchaseOnDiorParagraph')}</p>
                                <div className="AddressButton">
                                    <Link
                                        to={diorProductUrl}
                                        target={'_blank'}
                                        id="purchaseOnDior-button"
                                        className="Fgift-button">{t('goToDior')}</Link>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div className="HowToBuyContainer">
                <h1>{t('howToBuyH1')}</h1>
                <div className="HowToBuy">
                    <Accordion style={{width: '100%', padding: '10px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className="HowToBuySectionTitle">{t('bookAppointment')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{t('howToBuyP1')}</p>
                            <div className="AddressName">
                                <p>{boutique.name}</p>
                            </div>
                            <div className="WishlistAddress">
                                <FmdGoodIcon/>
                                <p>{boutique.address_line_1}, {boutique.zip_code} {boutique.city}</p>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{width: '100%', padding: '10px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="HowToBuySectionTitle">{t('contactUs')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{t('howToBuyP2')}</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{width: '100%', padding: '10px'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className="HowToBuySectionTitle">{t('purchaseOnDior')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>{t('purchaseOnDiorParagraph')}</p>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HowToBuy);
