import React, {useEffect, useState} from 'react';
import { login } from '../../services/authService';
import jwt from 'jwt-decode';
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router";
import Cookies from 'js-cookie';

import {frontendBaseURL, isJwtExpired, mainRolesRoutes, rolesCodes} from "../../global";
import {validateEmail, validatePassword} from "../../services/CheckUtils";
import dataProvider from "../../provider/api-interface";

import {
    FormControl,
    InputLabel,
    Input,
    Checkbox,
    FormHelperText,
    Button,
    TextField,
    Modal,
    Typography,
    Alert,
    AlertTitle,
    Collapse,
    IconButton, InputAdornment
} from '@mui/material';
import Header from '../Header';
import './style.css';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useSearchParams} from "react-router-dom";

interface NewPassword extends WithTranslation {
    role: string;
    routeRedirect: string;
}

function NewPassword() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    if (
        (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined ||
            sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined)
    ) {
        let jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
        if (!isJwtExpired(jwtToken)) {
            let user: any = jwt(jwtToken!);
            let roleCode: number = parseInt(user.role);
            let role = rolesCodes[roleCode];
            /*if(roleCode === 3 && user.status === 0) {
                sessionStorage.clear();
                localStorage.clear();
            }*/
            navigate(`/${mainRolesRoutes[role]}/`);
        }
    }

    const [token, setToken] = useState<string>('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState<boolean>(true);
    const [confirmPasswordText, setConfirmPasswordText] = useState<string>('');

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentials'));

    const location = useLocation();

    useEffect(() => {
        const getUser = async () => {
            if (!window.location.pathname.split('/')[2]) {
                // Check if md5 encoded email is present
                return false;
            }
            let token = window.location.pathname.split('/')[2];

            const user = await dataProvider.checkToken(token, 'reset');

            if (!user) {
                setAlertText(t('invalidToken'));
                setAlertType("error");
                setAlertOpen(true);
                return false;
            }
            //console.log(user, 'user')
            /*if(user.role === 3) {
                let wishlistProductId = searchParams.get('id');
                let listCode = searchParams.get('listCode');
                setListCode(listCode!)
                console.log(wishlistProductId, 'wishlistProductId');
                if(wishlistProductId !== null) {
                    setProductId(wishlistProductId);
                }
            }*/

            setToken(token);
            setEmail(user.email);
        };

        getUser();
    }, []);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setIsPasswordValid(validatePassword(event.target.value));
        if (confirmPasswordText !== event.target.value) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            setConfirmPassword(false);
        } else {
            setAlertOpen(true);
            setAlertType("success");
            setAlertText(t("passwordsMatchingParagraph"));
            setConfirmPassword(true);
        }
    };

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 2000);
    };

    const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (password !== event.target.value) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            setConfirmPasswordText(event.target.value)
            setConfirmPassword(false);
        } else {
            setAlertOpen(true);
            setAlertType("success");
            setAlertText(t("passwordsMatchingParagraph"));
            setConfirmPasswordText(event.target.value)
            setConfirmPassword(true);
        }
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email === '' || password === '') {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("emptyFieldsParagraph"));
            autoClose();
            return;
        }

        if (!isPasswordValid) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("invalidPasswordParagraph"));
            autoClose();
            return;
        }

        if (!confirmPassword) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("passwordsNotMatchingParagraph"));
            autoClose();
            return;
        }

        const response: any = await dataProvider.setNewPassword(token, password);
        console.log(response)
        if(response == null) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t('errorWhileRegistering'));
            autoClose();
            return false;
        }

        sessionStorage.setItem('token', response.token);

        let user: any = jwt(response.token);


        let roleCode: number = parseInt(user.role);
        let role = rolesCodes[roleCode];
        if(roleCode === 0 || roleCode === 1) {
            navigate(`/${mainRolesRoutes[role]}/`);
        } else {
            if(roleCode === 2) {
                dataProvider.getWishlistByGifteeId(user.id, response.token).then((wishlist: any) => {
                    if (wishlist.data !== null) {
                        console.log(wishlist, 'wishlist');
                        console.log('redirect to wishlist ok')
                        navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                    } else {
                        console.log('redirect to login success ok')
                        navigate(`/${mainRolesRoutes[role]}/login-success`);
                    }
                });

            }

            /*if (roleCode === 3) {
                if(productId !== null) {
                    navigate(`/${mainRolesRoutes[role]}/registry/create-message?id=${productId}&listCode=${listCode}`);
                } else {
                    navigate(`/${mainRolesRoutes[role]}/404`);
                }
            }*/
        }

    };

    const SubmitButton = () => {
        return <button type="submit" className="Fgift-button">
            Set new password
        </button>
    }

    return (
        <div>
            <Header />
            <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                <Alert severity={alertType}
                       onClose={() => setAlertOpen(false)}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setAlertOpen(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit" />
                           </IconButton>
                       }
                >
                    {alertText}
                </Alert>
            </Collapse>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <form className="LoginForm" onSubmit={handleFormSubmit}>
                        <div className="FormTitle"><h4>SET UP YOUR NEW PASSWORD</h4></div>
                        <div className="EmailContainer">
                            <div className="FormLabel"><span>EMAIL</span></div>
                            <TextField
                                label={t("emailLabel")}
                                value={email}
                                disabled
                                error={!isEmailValid}
                                helperText={!isEmailValid && t('notValidMailHelper')}
                                variant="standard"
                            />
                        </div>
                        <div className="PasswordContainer">
                            <div className="FormLabel"><span>PASSWORD</span></div>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                value={password}
                                onChange={handlePasswordChange}
                                error={!isPasswordValid}
                                helperText={!isPasswordValid && t('invalidPasswordParagraph')}
                                variant="standard"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Confirm password"
                                onChange={handleConfirmPassword}
                                variant="standard"
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className="ButtonContainer">
                            <SubmitButton />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(NewPassword);
