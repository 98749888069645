import React, {useEffect, useState} from "react";

import {withTranslation, useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

import {useLocation} from "react-router";
import './style.css';
import Header from "../Header";
import dataProvider from "../../provider/api-interface";
import jwt from "jwt-decode";
import {mainRolesRoutes, rolesCodes} from "../../global";

function LoginSuccess() {

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwtToken !== null ? jwt(jwtToken!) : null;

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    useEffect(() => {
        if(user === null) {
            navigate('/404');
            return;
        }
        let roleCode: number = parseInt(user.role);
        let role = rolesCodes[roleCode];
        const fetchData = async () => {
            await dataProvider.getWishlistByGifteeId(user.id, token).then((wishlist: any) => {
                if (wishlist.data !== null) {
                    console.log(wishlist)
                    //console.log(`/${mainRolesRoutes[role]}/registry/${wishlist[0].id}`)
                    navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                } else {
                    console.log('redirect to login success ok')
                    //return;
                   //navigate(`/${mainRolesRoutes[role]}/login-success`);
                }
            });
        }
        fetchData();
    }, [token]);

    return (
        <div className="row no-gutter">
            <div className="col-12 col-lg-3 d-md-block d-none">
            </div>
            <div className="LoginSuccessContainer col-12 col-lg-6">
                <Header /*hideLanguageSelect={user.role === 2}*/ />
                <div className="LoginSuccess">
                    <h1>{t('loginSuccessH1')}</h1>
                    {<p>{t('loginSuccessP')}</p>}
                </div>
            </div>
            <div className="col-12 col-lg-3 d-md-block d-none">
            </div>
        </div>
    );
}

export default withTranslation()(LoginSuccess);