import axios from 'axios';
const ApiBaseURL = process.env.REACT_APP_API_BASE_URL;

const validatePassword: any = (password: string) => {
    const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
}

const validateEmail: any = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export { validateEmail, validatePassword};
