import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import './PageNotFound.css';
import { Link } from 'react-router-dom';
import Header from '../Header';
import { mainRolesRoutes } from '../../global';


function PageNotFound() {
    const { t, i18n } = useTranslation();

    const checkPathName = () => {
        let rolePathName: any = window.location.pathname.split('/')[1];
        if (Object.values(mainRolesRoutes).indexOf(rolePathName) === -1) {
            return 'giftee/login';
        } else  {
            return rolePathName;
        }
    }

    const rolePathName = checkPathName();
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    return (
        <div className="PageNotFoundContainer">
            <Header />
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <div className="PageNotFound">
                        <h1>{t('pageNotFoundH1')}</h1>
                        <p>{t('pageNotFoundP')}</p>
                    </div>
                    <div className="ButtonContainer">
                        <Link className="Fgift-button" to={`/`}>
                            {t('backHomeButton')}
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(PageNotFound);
