import React from "react";
import loaderImage from '../media/images/DiorLoader.gif';


export const Loader = (): JSX.Element => {
    return (
        <div className="LoaderContainer">
            <img
                src={loaderImage}
                alt="loader"
            />
        </div>
    )
}