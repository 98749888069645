import jwtDecode from "jwt-decode";

interface MainRolesRoutes {
    [key: string | number]: any;
}

const rolesCodes: any = {
    0: 'salesAssistant',
    1: 'callCenter',
    2: 'giftee',
    3: 'gifter'
}

const mainRolesRoutes: MainRolesRoutes = {
    salesAssistant: 'salesassistant',
    gifter: 'gifter',
    giftee: 'giftee',
    callCenter: 'csa'
};

const isJwtExpired = (token: any) => {
    if (typeof(token) !== 'string' || !token) {
        console.log('invalid token provided');
        return true;
    }

    let isJwtExpired = false;
    const { exp }: any = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > exp) isJwtExpired = true;

    return isJwtExpired;
}

const checkPathName = (pathname: string, token: string) => {
    let user: any = jwtDecode(token);
    let userRoleCode = user.role;
    let userRole = rolesCodes[userRoleCode];
    let userRoleRoute = mainRolesRoutes[userRole];

    if (pathname !== userRoleRoute) {
        return false;
    }

    return true;
}

const apiBaseURL: any = process.env.REACT_APP_API_BASE_URL;
const frontendBaseURL: any = process.env.REACT_APP_BASE_URL;

export { rolesCodes, mainRolesRoutes, apiBaseURL, frontendBaseURL, isJwtExpired, checkPathName };






