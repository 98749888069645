const setFilterSortProducts = (queryStringFilters: any, queryStringSort: any = '') => {

    //console.log(queryStringFilters, 'queryStringFilters');
    let activeFilters = JSON.parse(queryStringFilters);
    //console.log(activeFilters, 'activeFilters');
    let sortString = '';
    if (queryStringSort !== undefined && queryStringSort !== null) {
        sortString = queryStringSort;
    }

    let activeCategoryFilter: any = [];
    let activeSizeFilter: any = [];
    let activeColorFilter: any = [];
    let activeGenderFilter: any = [];
    let searchString: any = '';

    if(activeFilters !== null) {
        if(activeFilters.sub_category_id !== undefined) {
            activeCategoryFilter = activeFilters.sub_category_id;
        }

        if(activeFilters.size_label !== undefined) {
            //console.log(activeFilters.size_label, 'activeFilters.size_label')
            let sizeLabels = activeFilters.size_label;
            sizeLabels.map((item: any) => {
                activeSizeFilter.push(`"${item}"`);
            });
        }

        if(activeFilters.color !== undefined) {
            activeFilters.color.map((item: any) => {
                activeColorFilter.push(`"${item}"`);
            });
        }

        if(activeFilters.gender !== undefined) {
            activeFilters.gender.map((item: any) => {
                activeGenderFilter.push(`"${item}"`);
            });
        }

        if(activeFilters.q !== undefined) {
            console.log(activeFilters.q, 'activeFilters.q')
            searchString = activeFilters.q;
        }
    }

    let filterCategoryString = '';
    let filterColorString = '';
    let filterSizeString = '';
    let filterGenderString = '';
    let filterString = '';

    if(activeCategoryFilter.length > 0) {
        filterCategoryString = filterCategoryString+`,"sub_category_id" : [${activeCategoryFilter}]`;
    }

    if(activeColorFilter.length > 0) {
        filterColorString = filterColorString+`,"color" : [${activeColorFilter}]`;
    }

    if(activeSizeFilter.length > 0) {
        filterSizeString = filterSizeString+`,"size_label" : [${activeSizeFilter}]`;
    }

    if(activeGenderFilter.length > 0) {
        filterGenderString = filterGenderString+`,"gender" : [${activeGenderFilter}]`;
    }

    sortString = sortString !== '' ? `,"sort" : ${sortString}` : '';
    filterString = filterCategoryString+filterSizeString+filterColorString+filterGenderString;
    searchString = searchString !== '' ? `,"q" : "${searchString}"` : '';

    //console.log(wishlist, 'wishlist in handleActiveFilters');
    let filterSort: any = {
        filter: filterString,
        sort: sortString,
        search: searchString
    };

    return filterSort;

}

export {setFilterSortProducts};