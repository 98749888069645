import React, {useEffect, useState} from "react";
import Header from "../Header";

import * as EmailValidator from 'email-validator';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { isValidPhoneNumber } from 'react-phone-number-input'

import { PhoneInput } from 'react-international-phone';

import {Alert, BaseTextFieldProps, Collapse, FormControl, IconButton, InputLabel, TextField} from '@mui/material';
import {checkPathName} from "../../global";
import jwt from "jwt-decode";
import dataProvider from "../../provider/api-interface";
import {frontendBaseURL} from "../../global";

import './CreateGiftee.css';

import { useTranslation, withTranslation } from 'react-i18next';
import {useNavigate, useParams} from "react-router";
import CloseIcon from "@mui/icons-material/Close";

import 'react-international-phone/style.css';

import {
    InputAdornment,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import {
    defaultCountries,
    FlagEmoji,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface MUIPhoneProps extends BaseTextFieldProps {
    value: string;
    onChange: (phone: string) => void;
    defaultCountryString: string;
}

const MuiPhone: React.FC<MUIPhoneProps> = ({
                                               value,
                                               onChange,
                                                defaultCountryString,
                                               ...restProps
                                           }) => {
    const { t, i18n } = useTranslation();
    const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
        usePhoneInput({
            defaultCountry: defaultCountryString,
            value,
            countries: defaultCountries,
            onChange: (data) => {
                onChange(data.phone);
            },
        });
    return (
        <TextField
            variant="standard"
            label={t("phoneLabel")}
            color="primary"
            placeholder="Phone number"
            value={phone}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: '2px', marginLeft: '-8px' }}
                    >
                        <Select
                            MenuProps={{
                                style: {
                                    height: '300px',
                                    width: '360px',
                                    top: '10px',
                                    left: '-34px',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            sx={{
                                width: 'max-content',
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: 'block',
                                    },
                                },
                                // Update default spacing
                                '.MuiSelect-select': {
                                    padding: '8px',
                                    paddingRight: '24px !important',
                                },
                                svg: {
                                    right: 0,
                                },
                            }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagEmoji iso2={value} style={{ display: 'flex' }} />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagEmoji
                                            iso2={country.iso2}
                                            style={{ marginRight: '8px' }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                ),
            }}
            {...restProps}
        />
    );
};

interface Giftee {
    id: string;
    name: string;
    surname: string;
    civility: string;
    email: string;
    phone: string;
    id_dior_star: string;
}

function CreateGiftee() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const [pageType, setPageType] = React.useState<string>("");

    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [civility, setCivility] = useState<string>("");
    const [phone, setPhone] = useState<any>("");
    const [diorStarId, setDiorStarId] = useState<any>("");

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<any>("");
    const [buttonText, setButtonText] = useState<string>("");
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [locale, setLocale] = useState<string>("en_gb");
    const [diorLocaleID, setDiorLocaleID] = useState<any>(null);
    const [defaultCountry, setDefaultCountry] = useState<any>(locale.split('_').pop());
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const [boutiques, setBoutiques] = useState<any>([]);
    const [selectedBoutiqueID, setSelectedBoutiqueID] = useState<any>("");
    const [selectedBoutiqueLocaleID, setSelectedBoutiqueLocaleID] = useState<any>(null);

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const getLocale = async () => {
        let response = await dataProvider.getOne('boutique',user.boutique_id, jwtToken);
        //console.log(response)
        if(response !== null) {
            setLocale(response.dior_locale_name);
            if(response.dior_locale_id === 1) {
                setTitles(titlesFR);
            } else {
                setTitles(titlesEN);
            }
        }
    }

    const getBoutiques = async (filterString = '') => {
        let response = await dataProvider.get(`boutique${filterString}`, jwtToken);
        //console.log(response)
        if(response !== null) {
            setBoutiques(response.data);
        }
    }

    //console.log('locale: ' + locale);
    useEffect(() => {
        setPageType(window.location.pathname.split('/')[2]);
        if(user.role === 1) { //CSC
            let filterString = '';
            console.log(pageType, 'pageType');
            if(pageType !== 'create-giftee' && diorLocaleID !== null) {
                console.log('here', diorLocaleID);
                filterString = `?filter={"dior_locale_id":${diorLocaleID}}`;
            }
            getBoutiques(filterString);
        }

        if(user.role === 0) { //Sales Assistant
            let filterString = `?filter={"dior_locale_id":${user.dior_locale_id}}`;
            getBoutiques(filterString);
        }


            if(pageType !== 'create-giftee' && pageType !== "") {
            const gifteeId = window.location.pathname.split('/')[4];
            const getGiftee = async () => {
                let response = await dataProvider.getOne('user', gifteeId, jwtToken);
                if(response !== null) {
                    console.log(response);
                    setName(response.name !== null && response.name !== 'undefined' ? response.name : '');
                    setSurname(response.surname !== null && response.surname !== 'undefined' ? response.surname : '');
                    setEmail(response.email !== null && response.email !== 'undefined' ? response.email : '');
                    setCivility(response.civility !== null && response.civility !== 'undefined' ? response.civility : '');
                    setLocale(response.locale === 1 ? 'fr_fr' : 'en_gb');
                    setDiorLocaleID(response.dior_locale_id);
                    setDefaultCountry(response.locale_id === 1 ? 'fr' : 'gb');
                    setTitles(response.locale_id === 1 ? titlesFR : titlesEN);
                    setSelectedBoutiqueID(response.boutique_id);
                    if(response.phone_number !== undefined
                        && response.phone_number !== null
                        && response.phone_number !== 'undefined'
                        && response.phone_number !== ''){
                        setPhone(response.phone_number);
                    }

                    if(response.id_dior_star !== 'null'
                        && response.id_dior_star !== undefined
                        && response.id_dior_star !== null
                        && response.id_dior_star !== 'undefined') {
                        setDiorStarId(response.id_dior_star);
                    }
                } else {
                    setAlertText(t('errorGettingGiftee'));
                    setAlertOpen(true);
                }
            }
            getGiftee();
        } else {
            setSelectedBoutiqueID(user.boutique_id);
            getLocale();
        }

    }, [pageType]);

    const titlesEN = [
        'Mr',
        'Mrs',
        'Miss',
        'Ms'
    ];

    const titlesFR = [
        'M.',
        'Mme',
        'Mlle'
    ];

    const [titles, setTitles] = useState<any>(titlesEN);


    const validateEmail = (email: string) => {
        return EmailValidator.validate(email);
    };

    const validatePhoneNumber = (phone: string) => {
        //console.log('+'+phone)
        //console.log(isValidPhoneNumber('+'+phone))
        return isValidPhoneNumber(phone);
    }

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setButtonDisabled(true);
        if(name === "") {
            setAlertText(t('emptyName'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if(surname === "") {
            setAlertText(t('emptySurname'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if(email === "") {
            setAlertText(t('emptyMail'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (civility === "") {
            setAlertText(t('emptyCivility'));
            setAlertOpen(true);
            setButtonDisabled(false);
            return;
        }

        if(phone === "") {
            setAlertText(t('emptyPhone'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        /*if(diorStarId === "") {
            setAlertText("The Dior Star ID cannot be empty");
            setAlertOpen(true);
            return;
        }*/

        if(!validateEmail(email)) {
            setAlertText(t('mailNotValid'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (!validatePhoneNumber(phone)) {
            console.log(phone);
            setAlertText(t('phoneNotValid'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        let created_by = null;
        if(user.role === 0) {
            created_by = 0;
        } else if(user.role === 1) {
            if(selectedBoutiqueID === null ||
                selectedBoutiqueID === undefined ||
                selectedBoutiqueID === "") {
                setAlertText(t('emptyBoutique'));
                setAlertOpen(true);
                setButtonDisabled(false);
                autoClose();
                return;
            }

            created_by = 1;
        }

        let data: any = {
            name: name,
            surname: surname,
            email: email,
            civility: civility,
            phone_number: phone,
            id_dior_star: diorStarId,
            boutique_id: selectedBoutiqueID,
            dior_locale_id: boutiques.filter((boutique: any) => boutique.id === selectedBoutiqueID)[0].dior_locale_id,
            locale_id: locale === 'en_gb' ? 2 : 1,
            role: 2, //giftee
            created_by: created_by
        };

        console.log(data, 'data');
        if(user.role === 0 && pageType === 'create-giftee') {
            data.sales_assistant_id = user.id;
        } else {
            data.sales_assistant_id = user.sales_assistant_id;
        }

        let response = null;
        //console.log(data); return;
        if(pageType === 'create-giftee') {
           response = await dataProvider.create('user' ,data, jwtToken);
        } else {
            const gifteeId = window.location.pathname.split('/')[4];
            response = await dataProvider.update('user', gifteeId, data, jwtToken);
        }
        if(response.data !== null) {
            console.log(response);

            if(pageType === 'create-giftee') {
                setAlertText(t('userCreated'));
            } else {
                setAlertText(t('userUpdated'));
            }
            setAlertType("success");
            setAlertOpen(true);
            setTimeout(() => {
                navigate(`/${pathName}/`);
            }, 2000);
        } else {
            setAlertText(t('userCreatingError'));
            if(response.status === 409) {
                setAlertText(t('emailAlreadyExists'));
            }
            setAlertOpen(true);
            setButtonDisabled(false);
        }
    }

    const handleLocaleChange = (e: any): any => {
        if(e.target.value === 'fr_fr') {
            setTitles(titlesFR);
            setLocale('fr_fr');
            setDefaultCountry('fr');

        } else {
            setTitles(titlesEN);
            setLocale('en_gb');
            setDefaultCountry('gb');
        }
    }

    const renderBoutiqueSelect = () => {
        if(user.role !== 1 && user.role !== 0) {
            return '';
        }

        return <FormControl fullWidth className="BoutiqueContainer">
            <InputLabel id="boutique-select-label">{t('titleClientBoutiqueLabel')}</InputLabel>
            <Select
                MenuProps={{autoFocus: false}}
                labelId="boutique-select-label"
                id="boutique-select"
                value={selectedBoutiqueID}
                onChange={(e) => setSelectedBoutiqueID(e.target.value)}
                variant="standard"
                required={true}
                style={{width: '100%'}}
                IconComponent={ExpandMoreIcon}
            >
                {boutiques?.map((boutique: any, i: any) => (
                    <MenuItem
                        value={boutique.id}
                        selected={user.boutique_id === boutique.id}
                        key={i}
                        style={{fontSize: '13px', fontFamily: 'CenturyGothic'}}
                    >
                        {boutique.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }



    const renderLocale = () => {
        return <FormControl fullWidth className="LocaleContainer">
            <InputLabel id="locale-select-label">{t('titleClientLanguageLabel')}</InputLabel>
            <Select
                MenuProps={{autoFocus: false}}
                labelId="locale-select-label"
                id="locale-select"
                value={locale}
                onChange={handleLocaleChange}
                variant="standard"
                required={true}
                style={{width: '100%'}}
                IconComponent={ExpandMoreIcon}
            >
                <MenuItem
                    value={'en_gb'}
                    selected={locale === 'en_gb'}
                    style={{fontSize: '13px', fontFamily: 'CenturyGothic'}}
                >
                    English
                </MenuItem>
                <MenuItem
                    value={'fr_fr'}
                    selected={locale === 'fr_fr'}
                    style={{fontSize: '13px', fontFamily: 'CenturyGothic'}}
                >
                    Français
                </MenuItem>
            </Select>
        </FormControl>
    }

    const renderCivility = () => {
        return  <FormControl fullWidth className="CivilityContainer">
            <InputLabel id="civility-select-label">{t('titleClientNameLabel')}</InputLabel>
            <Select
                MenuProps={{autoFocus: false}}
                labelId="civility-select-label"
                id="civility-select"
                value={civility}
                disabled={pageType === 'create-giftee' ? false : true}
                onChange={(e) => setCivility(e.target.value)}
                variant="standard"
                required={true}
                style={{width: '100%'}}
                IconComponent={ExpandMoreIcon}
            >
                {titles?.map((title: any, i: any) => (
                    <MenuItem
                        value={title}
                        selected={civility === title}
                        key={i}
                        style={{fontSize: '13px', fontFamily: 'CenturyGothic'}}
                    >
                        {title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>


    }

    return (
        <div style={{height: '100vh'}}>
            <Header link={`/${pathName}/`} textLink={t("linkText")} />
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={alertType}
                        >{alertText}</Alert>
                    </Collapse>
                    <form className="GifteeForm" onSubmit={handleSubmit}>
                <div className="GifteeTitle">
                    <h4>{t("createGifteeTitle")}</h4>
                </div>
                <TextField
                    label={t("nameLabel")}
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    required={true}
                    variant="standard"
                />
                <TextField
                    label={t("surnameLabel")}
                    value={surname}
                    onChange={(event) => {
                        setSurname(event.target.value);
                    }
                    }
                    required={true}
                    variant="standard"
                />
                {renderBoutiqueSelect()}
                {renderLocale()}
                {renderCivility()}
                <TextField
                    label={t("emailLabel")}
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }
                    }
                    required={true}
                    variant="standard"
                />
                <MuiPhone value={phone} onChange={(event) => {
                    setPhone(event);
                }}
                defaultCountryString={defaultCountry}
                ></MuiPhone>
                <TextField
                    label="Dior Client ID"
                    value={diorStarId}
                    onChange={(event) => {
                        setDiorStarId(event.target.value);
                    }
                    }
                    variant="standard"
                />
                <div className="ButtonContainer">
                    <button disabled={buttonDisabled} type="submit" style={{marginTop: '64px'}} className="Fgift-button">
                        {pageType === 'create-giftee' ? t("confirmAccountCreation") : t("confirmAccountEdit")}
                    </button>
                </div>
            </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );


}
export default withTranslation()(CreateGiftee);