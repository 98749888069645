import React, { useEffect, useState } from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import headerBackground from '../../media/images/GiftlistHeader.png';
import DiorLogo from '../../media/images/Dior.svg';

import {useNavigate} from "react-router";

import Header from '../Header';

import {mainRolesRoutes, checkPathName, rolesCodes} from "../../global";
import jwt from "jwt-decode";

import { useTranslation, withTranslation } from 'react-i18next';

import './Message.css';
import dataProvider from "../../provider/api-interface";

function Message() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [searchParams, setSearchParams] = useSearchParams();

    const wishlistId = searchParams.get('wishlistId');
    const productId: any = searchParams.get('id');

    if((wishlistId === undefined || wishlistId === null || wishlistId === '') &&
        (productId === undefined || productId === null || productId === '')) {
        navigate(`/${pathName}/404`);
    }

    const [wishlist, setWishlist] = useState<any>(null);
    const [product, setProduct] = useState<any>(null);
    const [gifter, setGifter] = useState<any>(null);

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    if(user.role !== 2) {
        navigate('/404');
    }
    const fetchWishlist = async () => {
        await dataProvider.getWishlistByListCode(wishlistId, jwtToken).then((response: any) => {
            console.log(response);
            setWishlist(response);
            setPurchasedProduct(response.products);
        }).catch((error: any) => {
            navigate(`/${pathName}/404`);
        })
    }

    const setPurchasedProduct = async (products: any) => {
        const purchasedProduct = products.find((product: any) => product.id === productId);
        console.log(purchasedProduct, 'purchasedProduct');
        setProduct(purchasedProduct)
        getGifter(purchasedProduct.gifter_id);
    }

    const getGifter = async (gifterId: any) => {
        await dataProvider.getOne('user',gifterId, jwtToken).then((response: any) => {
            console.log(response, 'gifter');
            setGifter(response);
        }).catch((error: any) => {
            console.log(error, 'error');
            //navigate(`/${pathName}/404`);
        })
    }

    useEffect(() => {
        fetchWishlist();
    }
    , []);

    const pageStyle = {
        background: `url(${headerBackground})`,
        height: '100vh',
    }
    //console.log(product, 'product');
    let wishlistUrl = null;
    if(user.role === 2){
        wishlistUrl = `/${pathName}/registry/${wishlistId}`;
    } else if(user.role === 3){
        wishlistUrl = `/${pathName}/${wishlistId}`;
    }
    return (
        <div style={pageStyle} className="MessagePageContainer">
            <Header /*hideLanguageSelect={user.role === 2}*/ wishlistUrl={wishlistUrl} link={`/${pathName}/registry/${wishlist?.list_code}`} textLink={t('backTo') + ' ' + wishlist?.name}/>
            <div className="MessageHeader">
                <div className="MessageImage">
                    <img src={product?.image_url} />
                </div>
                <div className="MessageProductTitle">
                    <h3>{product?.title}</h3>
                </div>
            </div>
            <div className="MessageContainer">
                <div className="MessageTextLogo">
                    <img src={DiorLogo} />
                </div>
                <div className="MessageText">
                    {product?.message_body}
                </div>
                <div className="row MessageSender">
                    <div className="col-12">
                        {gifter?.name} {gifter?.surname}
                    </div>
                    <div style={{paddingTop: '7px', textDecoration: 'underline'}} className="col-12">
                        <Link style={{color: '#757575'}} to={`mailto:${gifter?.email}`} >{gifter?.email}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Message);