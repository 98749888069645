import React, {useEffect} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {Link, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router";

import './FooterDior.css';

interface FooterProps {
    showDisclaimer?: boolean;
    locale?: string;
}


function FooterDior({showDisclaimer = true, locale= 'fr_fr'}: FooterProps) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let disclaimerClass = 'col-12 FooterDisclaimer d-inline-flex';
    if(!showDisclaimer) {
        disclaimerClass = 'col-12 FooterDisclaimer d-none';
    }

    return (
        <div>
            <div className="row">
                <div className={disclaimerClass}>
                    <p>{t('footerDior.disclaimer')}</p>
                </div>
            </div>
            <div className="FooterDior row">
                <div className="FooterSection col-12 col-md-4">
                    <h4>{t('footerDior.clientService')}</h4>
                    <Link target={'_blank'} to={
                        locale === 'fr_fr' ? `https://www.dior.com/fr_fr/fashion/contact-couture` : `https://www.dior.com/${locale}/fashion/contact-couture`
                    }>{t('footerDior.contactUs')}</Link>
                </div>
                <div className="FooterSection col-12 col-md-4 mt-5 mt-md-0">
                    <h4>{t('footerDior.houseOfDior')}</h4>
                    <a target='_blank' href='/documents/Terms_Conditions.pdf'>{t('footerDior.terms')}</a>
                    <Link target={'_blank'} to={
                        locale === 'fr_fr' ? `https://www.dior.com/fr_fr/fashion/mentions-legales#mentions-legales` : `https://www.dior.com/${locale}/fashion/legal-terms`
                    }>{t('footerDior.mentions')}</Link>
                    <a target='_blank' href='/documents/Privacy_Policy.pdf'>{t('footerDior.policy')}</a>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(FooterDior)
