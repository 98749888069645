import React, {useEffect, useState} from "react";

import {withTranslation, useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

import {useLocation} from "react-router";
import './PublicRegistrationSuccess.css';
import Header from "../Header";
import FooterDior from "./FooterDior";
import headerBackground from "../../media/images/FreeRegistrationBackground.jpeg";

function PublicRegistrationSuccess() {

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    return (
        <div>
            <Header hideLanguageSelect={false}/>
            <div className="row no-gutter">
                <div className="col-12 Courtesy LandingImage" style={{
                    background: `url(${headerBackground})`,
                    backgroundPosition: '20% 14%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                </div>
            </div>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="PrSuccessContainer col-12 col-lg-6">
                    <div className="PrSuccess">
                        <h1>{t('prSuccessH1')}</h1>
                        {<p>{t('prSuccessP')}</p>}
                    </div>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
            <FooterDior showDisclaimer={false}/>
        </div>
    );
}

export default withTranslation()(PublicRegistrationSuccess);