import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Popover, PopoverBody} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {checkPathName} from "../../global";
import jwt from "jwt-decode";
import dataProvider from "../../provider/api-interface";
import {useNavigate, useParams} from "react-router";
import dayjs from "dayjs";

import {frontendBaseURL, mainRolesRoutes, rolesCodes} from "../../global";

import './WishlistsPreview.css';
import { Alert, Collapse, IconButton } from '@mui/material';
import EditIcon from '../../media/images/Edit.svg';

import CloseIcon from '@mui/icons-material/Close';
import {Loader} from '../Loader';

import { useTranslation, withTranslation } from 'react-i18next';
import {decode} from 'html-entities';

interface Wishlist {
    id: string;
    name: string;
    shop_assistant: string;
    description: string;
    start_date: string;
    end_date: string;
    created_at: string;
    giftee: Giftee;
    sales_assistant: any;
    user_id: any;
    products: any[];
    list_code: any;
    wishlist_password: any;
}

interface Giftee {
    id: string;
    name: string;
    surname: string;
}

function WishlistsPreview(props: any) {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const wishlists = props.wishlists;
    const isLoading = props.isLoading;
    console.log(wishlists, 'wishlists');

    const [pathName, setPathName] = React.useState<string>(window.location.pathname.split('/')[1]);
    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const [notificationOpen, setNotificationOpen] = useState(false);

    const autoClose = () => {
        setTimeout(() => {
            setNotificationOpen(false);
        }, 3000);
    };

    const editWishlistButton = (wishlist: Wishlist) => {
        //if(user.role === 1){ //CSC can't edit wishlist
        //    return <span></span>;
        //}

        return <div className="WishlistMoreContainer" style={{cursor: 'pointer'}}>
            <Link to={`/${pathName}/registry/edit/${wishlist.list_code}`}>
                {t('edit')} <img src={EditIcon} />
            </Link>
        </div>;
    }


    const wishlistTemplate = (wishlist: Wishlist, index: number) => {
        return (
            <div key={index} className="WishlistContainer">
                <div className="row">
                    <div className="col-6">
                        <div className="WishlistNameContainer">
                            <Link to={`/${pathName}/registry/${wishlist.list_code}`}><h4>{decode(wishlist.name)}</h4></Link>
                        </div>
                    </div>
                    <div className="col-6">
                        {editWishlistButton(wishlist)}
                    </div>
                    <div className="col-12">
                        <div className="WishlistId">
                            <p>ID: {wishlist.list_code}</p>
                            <p>{t('password')}: {wishlist.wishlist_password}</p>
                        </div>
                    </div>
                    <div className="WishlistCreatedBy">
                        <p>{t('createdBy')}: {wishlist.sales_assistant !== null ? wishlist.sales_assistant.name : ''} {wishlist.sales_assistant !== null ? wishlist.sales_assistant.surname : ''}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                    <div className="WishlistGiftee">
                            <p>Client: {wishlist.giftee !== null ? wishlist.giftee.name : '' } {wishlist.giftee !== null ? wishlist.giftee.surname : '' }</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="WishlistEventDateContainer">
                            <p style={{fontWeight: '700'}}>Event date: {dayjs(wishlist.start_date).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="WishlistItemsContainer">
                            <p>
                                {wishlist.products !== null ? wishlist.products.length : 0} {t('items')}
                            </p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="WishlistEventDateContainer">
                            <p>List ends on: {dayjs(wishlist.end_date).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderWishlists = () => {
        if (wishlists === null) {
            return (
                <div className="WishlistsContainer">
                    <div className="NoWishlistContainer">
                        <h4 style={{ textTransform: 'uppercase' }}>{t('noWishlistH4')}</h4>
                        <p>{t('noWishlistP')}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="WishlistsContainer">
                    {/*<div className="SearchBarContainer">
                        <SearchBar />
                    </div>*/}
                    {wishlists.map((wishlist: any, index: any) => {
                        return wishlistTemplate(wishlist, index);
                    })}
                </div>
            );
        }
    };

    if(isLoading) {
        return <Loader />
    }


    return (
        <div className="WishlistsContainer">
            <Collapse className="FgiftCustomCollapse" in={notificationOpen}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setNotificationOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity="info"
                >
                    {t('wishlistUrlCopied')}
                </Alert>
            </Collapse>
            {renderWishlists()}
        </div>
    );
}

export default withTranslation()(WishlistsPreview);
