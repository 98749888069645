import React, {useEffect, useState} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import headerBackground from '../../media/images/HomeHeader.jpeg';
import { Link } from 'react-router-dom';

import GifteesPreview from './GifteesPreview';

import './Home.css';
import Header from '../Header';
import SearchBar from "../search-engine/SearchBar";

import '../../App.css';
import { useTranslation, withTranslation } from 'react-i18next';
import {mainRolesRoutes, checkPathName} from "../../global";
import {useNavigate} from "react-router";
import jwt from "jwt-decode";
import dataProvider from "../../provider/api-interface";

function Home() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [buttonText, setButtonText] = useState(t('createClientButton'));
    const [buttonLink, setButtonLink] = useState(`/${pathName}/create-giftee`);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [giftees, setGiftees] = useState<any>(null);
    const [gifteesNumber, setGifteesNumber] = useState<number>(0);
    const [wishlists, setWishlists] = useState<any>(null);
    const [wishlistsNumber, setWishlistsNumber] = useState<number>(0);
    const [webWishlists, setWebWishlists] = useState<any>(null);
    const [webWishlistsNumber, setWebWishlistsNumber] = useState<number>(0);

    const [activeBoutiqueFilter, setActiveBoutiqueFilter] = useState<any>(null);
    const [activeBoutiqueId, setActiveBoutiqueId] = useState<any>(null);

    const [activeLocaleId, setActiveLocaleId] = useState<any>(null);

    //Use anywhere
    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere


    const headerStyle = {
        background: `url(${headerBackground})`,
        backgroundSize: 'cover',
    };

    useEffect(() => {
        const fetchGiftees = async (activeLocaleId: any, boutiqueFilter: any) => {
            if (gifteesNumber === 0) {
                if(parseInt(user.role) === 0) { //Get users by boutique id for sales Assistant
                    await dataProvider.getGifteesByLocale(user.dior_locale_id, jwtToken, boutiqueFilter).then((response: any) => {
                        //console.log(response)
                        if(response !== null) {
                            setGiftees(response.data);
                            setGifteesNumber(response.data ? response.data.length : 0);
                        }
                    });
                }

                if(parseInt(user.role) === 1) { //Get all users for CSC
                    if(activeLocaleId !== null) {
                        await dataProvider.getGifteesByLocale(activeLocaleId, jwtToken).then((response: any) => {
                            //console.log(response, 'giftees')
                            if(response !== null) {
                                setGiftees(response.data);
                                setGifteesNumber(response.data.length);
                            }
                        });
                    } else {
                        await dataProvider.getAllGiftees(jwtToken, boutiqueFilter).then((response: any) => {
                            //console.log(response, 'giftees')
                            if(response !== null) {
                                setGiftees(response.data);
                                setGifteesNumber(response.data.length);
                            }
                        });
                    }
                }

                setIsLoading(false);

            }
        };
        fetchGiftees(activeLocaleId, activeBoutiqueFilter);
        const fetchWishlists = async (activeLocaleId: any, boutiqueFilter: any) => {
            if (wishlistsNumber === 0) {
                if(parseInt(user.role) === 0) { //Get wishlists by boutique id for sales Assistant
                    await dataProvider.getWishlistByLocaleId(user.dior_locale_id, jwtToken, boutiqueFilter).then((response: any) => {
                        if(response !== null) {
                            setWishlists(response.data)
                            setWishlistsNumber(response.total);
                        }
                    });
                }

                if(parseInt(user.role) === 1) { //Get all wishlists for CSC
                    if(activeLocaleId !== null) {
                        await dataProvider.getWishlistByLocaleId(activeLocaleId, jwtToken).then((response: any) => {
                            if(response !== null) {
                                setWishlists(response.data)
                                setWishlistsNumber(response.total);
                            }
                        });
                    } else {
                        await dataProvider.getAllWIshlists(jwtToken, boutiqueFilter).then((response: any) => {
                            if(response !== null) {
                                setWishlists(response.data)
                                setWishlistsNumber(response.total);
                            }
                        });
                    }
                }

            }
        };
        fetchWishlists(activeLocaleId, activeBoutiqueFilter);

        const fetchWebWislists = async (activeLocaleId: any, boutiqueFilter: any) => {
            if (webWishlistsNumber === 0) {
                if(parseInt(user.role) === 0) { //Get wishlists by boutique id for sales Assistant
                    await dataProvider.getWebWishlistByLocaleId(user.dior_locale_id, jwtToken, boutiqueFilter).then((response: any) => {
                        if(response !== null) {
                            setWebWishlists(response.data)
                            setWebWishlistsNumber(response.total);
                        }
                    });
                }

                if(parseInt(user.role) === 1) { //Get all wishlists for CSC
                    if(activeLocaleId !== null) {
                        await dataProvider.getWebWishlistByLocaleId(activeLocaleId, jwtToken).then((response: any) => {
                            if(response !== null) {
                                setWebWishlists(response.data)
                                setWebWishlistsNumber(response.total);
                            }
                        });
                    } else {
                        await dataProvider.getAllWebWishlists(jwtToken, boutiqueFilter).then((response: any) => {
                            if(response !== null) {
                                setWebWishlists(response.data)
                                setWebWishlistsNumber(response.total);
                            }
                        });
                    }
                }

            }

        }
        fetchWebWislists(activeLocaleId, activeBoutiqueFilter);
    }, [activeLocaleId, activeBoutiqueFilter]);

    const renderGiftees = () => {
        if(giftees === null) {
            return (
                <div className="NoGifteesContainer">
                    <h4 style={{ textTransform: 'uppercase' }}>{t('noClientH4')}</h4>
                    <p>{t('noClientP')}</p>
                </div>
            );
        } else {
            return <SearchBar
                    searchQuery={'giftee'}
                    elements={giftees}
                    isLoading={isLoading}
                    token={jwtToken}
                    //setParentBoutiqueFilter={setActiveBoutiqueFilter}
                    //activeFilter={activeBoutiqueFilter}
                    //setParentActiveBoutiqueId={setActiveBoutiqueId}
                    //activeBoutiqueId={activeBoutiqueId}
                    setParentLocaleId={setActiveLocaleId}
                    activeLocaleId={activeLocaleId}
            />
        }
    }

    const renderWishlists = () => {
        if(wishlists === null) {
            return (
                <div className="WishlistsContainer">
                    <div className="NoWishlistContainer">
                        <h4 style={{ textTransform: 'uppercase' }}>{t('noWishlistH4')}</h4>
                        <p>{t('noWishlistP')}</p>
                    </div>
                </div>
            );
        } else {
            return <SearchBar
                searchQuery={'wishlist'}
                elements={wishlists}
                isLoading={isLoading}
                token={jwtToken}
                //setParentBoutiqueFilter={setActiveBoutiqueFilter}
                //activeFilter={activeBoutiqueFilter}
                //setParentActiveBoutiqueId={setActiveBoutiqueId}
                //activeBoutiqueId={activeBoutiqueId}
                setParentLocaleId={setActiveLocaleId}
                activeLocaleId={activeLocaleId}
            />
        }
    }

    const renderWebWishlists = () => {
        if(webWishlists === null) {
            return (
                <div className="WishlistsContainer">
                    <div className="NoWishlistContainer">
                        <h4 style={{ textTransform: 'uppercase' }}>{t('noWishlistH4')}</h4>
                        <p>{t('noWishlistP')}</p>
                    </div>
                </div>
            );
        } else {
            return <SearchBar
                searchQuery={'webWishlist'}
                elements={webWishlists}
                isLoading={isLoading}
                token={jwtToken}
                //setParentBoutiqueFilter={setActiveBoutiqueFilter}
                //activeFilter={activeBoutiqueFilter}
                //setParentActiveBoutiqueId={setActiveBoutiqueId}
                //activeBoutiqueId={activeBoutiqueId}
                setParentLocaleId={setActiveLocaleId}
                activeLocaleId={activeLocaleId}
            />
        }
    }

    const pageView = () => {
        const button = <Link className="Fgift-button" to={buttonLink}>
                {buttonText}
            </Link>;

        return (
            <div>
                <div className="HomePageHeader" style={headerStyle}>
                    <h1 className="HomeUserName">{t('welcomeText')} {user.name}!</h1>
                </div>
                <div className="row no-gutter">
                    <div className="col-12 col-lg-3 d-md-block d-none">
                    </div>
                    <div className="HomePageTabs col-12 col-lg-6">
                        <Tabs
                            defaultActiveKey="clients"
                            id="uncontrolled-tab-example"
                            onSelect={(eventKey) => {
                                if (eventKey === 'wishlists' || eventKey === 'webWishlists') {
                                    setButtonText(t('createWishlistButton'));
                                    setButtonLink(`/${pathName}/create-registry`);
                                } else if (eventKey === 'clients') {
                                    setButtonText(t('createClientButton'));
                                    setButtonLink(`/${pathName}/create-giftee`);
                                }
                            }}
                        >
                            <Tab eventKey="wishlists" title={<div><span style={{fontWeight: '400'}}>{wishlistsNumber}</span> {t('wishlist')}</div>} className="HomeTab">
                                <div className="HomePageTabContent">
                                    {renderWishlists()}
                                </div>
                            </Tab>
                            <Tab eventKey="webWishlists" title={<div><span style={{fontWeight: '400'}}>{webWishlistsNumber}</span> {t('webWishlist')}</div>} className="HomeTab">
                                <div className="HomePageTabContent">
                                    {renderWebWishlists()}
                                </div>
                            </Tab>
                            <Tab eventKey="clients" title={<div><span style={{fontWeight: '400'}}>{gifteesNumber}</span> {t('clientList')}</div>} className="HomeTab">
                                <div className="HomePageTabContent">
                                    {renderGiftees()}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="ButtonContainer">
                        {button}
                    </div>
                    <div className="col-12 col-lg-3 d-md-block d-none">
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Header />
            {pageView()}
        </div>
    );
}

export default withTranslation()(Home);
