// dataProvider.tsx
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';


import {apiBaseURL} from "./../global";

const axiosInstance = axios.create({
    baseURL: apiBaseURL,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            // Error handling based on response status code
            console.log(error);
            if (error.response.status === 401) {
                // Example: User is not authorized, redirects to a login page
                //window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

const dataProvider = {
    async login(email: string, password: string) {
        try {
            const response = await axiosInstance.post(`/user/login`, {
                email: email,
                password: password
            });

            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async get(resource: string, jwtToken: any) {
        try {
            const response = await axiosInstance.get(`/${resource}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },

    // Esempio di funzione per effettuare una richiesta POST
    async create(resource: string, data: any, jwtToken: any) {
        try {
            const response = await axiosInstance.post(`/${resource}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },

    async update(resource: string, id: string, data: any, token: any) {
        try {
            const response = await axiosInstance.put(`/${resource}/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async delete(resource: string, id: string, jwtToken: any) {
        try {
            const response = await axiosInstance.delete(`/${resource}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getOne(resource: string, id: any, jwtToken: any) {
        try {
            const response = await axiosInstance.get(`/${resource}/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwtToken
                    }
                });
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    //reset password
    async requestResetPassword(email: string): Promise<any>{
        try {
            const response = await axiosInstance.post(`/user/request-reset-password`, {
                email: email
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            return null;
        }
    },

    async setNewPassword(token: string, password: string): Promise<any>{
        try {
            const response = await axiosInstance.post(`/user/set-new-password`, {
                token: token,
                password: password
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            return null;
        }
    },

    async getGifteesWithoutWishlists(locale_id: any, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`user?filter={"without_wishlist": 1, "role": 2, "locale_id": "${locale_id}"}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getAllGifteesWithoutWishlist(jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`user?filter={"without_wishlist": 1, "role": 2}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getGifteesByBoutique(boutique_id: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`/user?filter={"boutique_id":"${boutique_id}","role":2}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },
    async getGifteesByLocale(dior_locale_id: number, jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/user?filter={"dior_locale_id":"${dior_locale_id}","role":2${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getAllGiftees(jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/user?filter={"role":2${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getAllWIshlists(jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"created_by": [0,1], "status":1${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getAllWebWishlists(jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"status":1,"created_by": 2${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getWishlistByGifteeId(giftee_id: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"giftee_id": "${giftee_id}"}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }

    },

    async getWishlistByBoutiqueId(boutique_id: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"created_by": [0,1],"boutique_id":"${boutique_id}"}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                //console.log(response.data);
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }

    },

    async getWebWishlistByBoutiqueId(boutique_id: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"boutique_id":"${boutique_id}","created_by": 2, "status": 1}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                //console.log(response.data);
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }

    },

    async getWishlistByLocaleId(dior_locale_id: number, jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"created_by": [0,1],"wishlist_dior_locale_id":${dior_locale_id}${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                //console.log(response.data);
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }

    },

    async getWebWishlistByLocaleId(dior_locale_id: number, jwtToken: any, activeBoutiqueFilter: any = null): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist?filter={"wishlist_dior_locale_id":${dior_locale_id},"created_by": 2, "status": 1${activeBoutiqueFilter !== null ? activeBoutiqueFilter : ''}}&perPage=-1`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                //console.log(response.data);
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }

    },

    async getProducts(): Promise<any>{
        try {
            const response = await axiosInstance.get(`/product`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async checkToken(token: string, type: any = null): Promise<any>{
        try {
            let resetPasswordString = '';
            if(type === 'reset'){
                resetPasswordString = '&type=reset';
            }
            const response = await axiosInstance.get(`/user/get-by-token?token=${token}${resetPasswordString}`);
            if (response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async confirmRegister(token: string, password: string, role: any, civility: any = null ): Promise<any>{
        try {
            let data = {};
            if(civility !== null){
                data = {
                    token: token,
                    password: password,
                    role: role,
                    civility: civility
                }
            } else {
                data = {
                    token: token,
                    password: password,
                    role: role
                }
            }
            const response = await axiosInstance.put(`/user/confirm`, data);
            if(response.status === 201){
                return response.data.token;
            }else{
                return null;
            }
        } catch (error) {
            return null;
        }
    },

    async addProductToWishlist(wishlist_id: string, product_id: string, variation_sku: string, fromGiftee: boolean, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.put(`/wishlist/${wishlist_id}`, {
                products: [
                    {
                        product_id: product_id,
                        variation_sku: variation_sku
                    }
                ],
                fromGiftee: fromGiftee
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async removeProductFromWishlist(wishlist_id: string, product_id: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/wishlist/remove-product`, {
                wishlist_id: wishlist_id,
                product_id: product_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async setItemPurchased(wishlist_id: any, gifter_id: any, purchased_id: any, wishlist_product_id: any, jwtToken: any): Promise<any>{
        console.log(gifter_id, wishlist_product_id, 'elements');
        try {
            const response = await axiosInstance.put(`/wishlist/${wishlist_id}`, {
                gifter: {
                    gifter_id: gifter_id,
                    purchased_id: purchased_id,
                    wishlist_x_product_id: wishlist_product_id
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });
            if(response.status === 200 || response.status === 201){
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async getWishlistByListCode(listCode: any, jwtToken: any, filterString: any = ''): Promise<any>{
        try {
            const response = await axiosInstance.get(`/wishlist/${listCode}${filterString}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwtToken
                    }
                });
            if(response.status === 200 || response.status === 201){
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async checkIfWishlistExists(listCode: any, wishListProductId: any = null): Promise<any>{
        try {
            let response;
            if(wishListProductId === null) {
                response = await axiosInstance.get(`/wishlist/check_if_exists/${listCode}`);
            } else {
                response = await axiosInstance.get(`/wishlist/check_if_exists/${listCode}?filter={"wishlist_x_product_id": "${wishListProductId}"}`);
            }
            if(response.status === 200 || response.status === 201){
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },
    //GIFTER
    async gifterLogin(password: string, listCode: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/user/gifter-login`, {
                list_code: listCode,
                wishlist_password: btoa(password)
            });
            if(response.status === 200 || response.status === 201){
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async setProductMessage(wishlist_id: any,wishlist_product_id: any, message_alias: string, message_body: string, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.put(`/wishlist/${wishlist_id}`, {
                gifter: {
                    wishlist_x_product_id: wishlist_product_id,
                    message_alias: message_alias,
                    message_body: message_body
                },
                finalize: true
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });

            if(response.status === 200 || response.status === 201) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async shareWishlistToGiftee(wishlist_id: any, jwtToken: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/wishlist/${wishlist_id}/share`, {
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwtToken
                }
            });

            if(response.status === 200 || response.status === 201) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    async unifiedRegistration(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/unified-registration`, data);
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    //new landing updates 07/2024
    async unifiedRegistrationStep1(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/unified-registration/step-1`, data);
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async getStarted(id: any, data: any): Promise<any>{
        try {
            console.log(data, 'here')
            const response = await axiosInstance.put(`/unified-registration/get-started/${id}`, data);
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async googleAuthLogin(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/user/auth/google`, data);

            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async googleAuthRegistration(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/unified-registration/auth/google`, data);
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async facebookAuthLogin(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/user/auth/facebook`, data);

            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                return null;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async facebookAuthRegistration(data: any): Promise<any>{
        try {
            const response = await axiosInstance.post(`/unified-registration/auth/facebook`, data);
            let res: any = {};
            if (response.status === 200 || response.status === 201) {
                res.status = response.status;
                res.data = response.data;
                return res;
            } else {
                res.status = response.status;
                res.data = null;
                return res;
            }
        } catch (error: any) {
            console.log(error);
            let res: any = {};
            res.status = error.response.status;
            res.data = null;
            return res;
        }
    },
    async confirmUnifiedRegistration(token: string): Promise<any>{
        try {
            const response = await axiosInstance.put(`/unified-registration/confirm`, {
                token: token
            });

            console.log(response, 'response');
            if(response.status === 200 || response.status === 201){
                return response;
            }else{
                return null;
            }
        } catch (error) {
            return null;
        }
    }

};

export default dataProvider;
