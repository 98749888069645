import React, {useEffect, useState} from "react";
import Header from "../Header";
import {
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    IconButton,
    Collapse, Checkbox, InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

import {useNavigate} from "react-router";

import {checkPathName, frontendBaseURL, mainRolesRoutes, rolesCodes} from "../../global";

import { useTranslation, withTranslation } from 'react-i18next';

import './CreateMessage.css';
import {useSearchParams} from "react-router-dom";
import jwt from "jwt-decode";
import dataProvider from "../../provider/api-interface";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


function CreateMessage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [searchParams, setSearchParams] = useSearchParams();

    if (
        (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined ||
            sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined)
    ) {
        // console.log('ciao reload');
        let tempToken: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
        let tempUser: any = jwt(tempToken);
        let tempuserRoleCode: any = parseInt(tempUser.role);
        if(tempuserRoleCode !== 3) {
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
        }
    }

    const [gifterName, setGifterName] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [onWriting, setOnWriting] = useState<boolean>(false);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('emptyMessage'));

    const wishlistProductId = window.location.pathname.split('/')[2];
    const wishlistCode = searchParams.get('listCode');
    //console.log(wishlistCode, 'wishlistCode');
    //console.log(wishlistProductId, 'wishlistProductId');

    const [giftee, setGiftee] = useState<any>(null);

    const [productName, setProductName] = useState<any>(null);
    const [productImage, setProductImage] = useState<any>(null);

    const [email, setEmail] = useState<any>('');

    const [wishlistId, setWishlistId] = useState<any>(null);
    const [wishlistName, setWislistName] = useState<any>(null);
    const [listCode, setListCode] = useState<any>(null);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
    const [termsStyle, setTermsStyle] = useState<any>({fontWeight: "400"});
    const [privacyStyle, setPrivacyStyle] = useState<any>({fontWeight: "400"});

    const [password, setPassword] = useState('');

    const StyledTextField = styled(TextField)`
      textarea {
        resize: both;
        width: 100%;
      }
  `;

    if((wishlistProductId === undefined || wishlistProductId === null || wishlistProductId === '') &&
        (wishlistCode === undefined || wishlistCode === null || wishlistCode === '')) {
        navigate(`/${pathName}/404`);
    }

    const handleTermsAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setTermsStyle({fontWeight: "700"});
            setTermsAccepted(true);
        } else {
            setTermsStyle({fontWeight: "400"});
            setTermsAccepted(false);
        }
    }

    const handlePrivacyAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setPrivacyStyle({fontWeight: "700"});
            setPrivacyAccepted(true)
        } else {
            setPrivacyStyle({fontWeight: "400"});
            setPrivacyAccepted(false)
        }
    }

    const getWishlist = async () => {
        if(wishlistCode !== null) {
            const response = await dataProvider.checkIfWishlistExists(wishlistCode, wishlistProductId);
            console.log(response, 'response');
            if(response === null) {
                navigate(`/404`);
            } else {
                setWishlistId(response.id);
                setListCode(response.list_code);
                setWislistName(response.name);
                setProductImage(response.product.image_url);
                setProductName(response.product.title);
                setEmail(response.gifter.email);
                setGifterName(response.gifter.name + ' ' + response.gifter.surname);
            }
        }
    };

    useEffect(() => {
        getWishlist();
    }, []);

    //console.log(wishlist,'wishlist');
    //console.log(giftee,'giftee');
    //console.log(product,'product');

    const handleGifterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGifterName(event.target.value);
    }

    const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (gifterName === "") {
            setAlertText(t('gifterNameEmpty'));
            setAlertType("error");
            setAlertOpen(true);
            return;
        }

        if(message === "") {
            setAlertText(t('emptyMessage'));
            setAlertType("error");
            setAlertOpen(true);
            return;
        }

        if (!termsAccepted) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("termsNotAcceptedParagraph"));
            return;
        }

        if (!privacyAccepted) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("privacyNotAcceptedParagraph"));
            return;
        }

        await dataProvider.gifterLogin(password, listCode).then((passwordCheck) => {

            if (passwordCheck === null) {
                setAlertOpen(true);
                setAlertType("error");
                setAlertText(t("wrongCredentialsGiftersParagraph"));
                return;
            } else {
                //console.log(passwordCheck, 'PASSWORD CHECK');
                //console.log("Gifter name: " + gifter);
                //console.log("Message: " + message);
                localStorage.setItem('token', passwordCheck.token);
                let user: any = jwt(passwordCheck.token);
                let roleCode: number = parseInt(user.role);
                let role = rolesCodes[roleCode];
                dataProvider.setProductMessage(wishlistId, wishlistProductId, gifterName, message, passwordCheck.token).then((response) => {
                    if (response === null) {
                        setAlertText(t('messageCreateError'));
                        setAlertOpen(true);
                    } else {
                        //console.log(response, 'response')
                        setAlertType("success");
                        setAlertText(t('messageCreateSuccess'));
                        setAlertOpen(true);
                        setTimeout(() => {
                           navigate(`/${mainRolesRoutes[role]}/registry/${listCode}`);
                        }, 2000);
                    }
                });
            }
        });

    }


    return (
        <div>
            <Header />
            <div className="MessageHeader d-flex d-lg-none">
                <div className="MessageImage">
                    <img src={productImage} />
                </div>
                <div className="MessageProductTitle">
                    <h3>{t('yourGift')}:<br/>{productName}</h3>
                </div>
            </div>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-lg-block d-none p-4">
                    <div className="FormTitle"><h4>{t('sendMessage')}</h4></div>
                    <div className="MessageHeader" style={{margin: '0'}}>
                        <div className="MessageImage">
                            <img src={productImage} />
                        </div>
                        <div className="MessageProductTitle">
                            <h3>{t('yourGift')}:<br/>{productName}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {alertText}
                        </Alert>
                    </Collapse>
                    <form className="CreateMessageForm" onSubmit={handleSubmit}>
                <div className="FormTitle d-block d-lg-none"><h4>{t('sendMessage')}</h4></div>
                <div className="EmailContainer">
                    <TextField
                        value={email}
                        disabled
                        variant="standard"
                    />
                </div>
                <div className="GifterNameContainer">
                    <TextField
                        label={t('gifterNameLabel')}
                        value={gifterName}
                        onChange={handleGifterName}
                        variant="standard"
                        style={{width: '100%'}}
                    />
                </div>
                <div className="GifterMessageContainer">
                    <TextField
                        label={t('commentLabel')}
                        multiline
                        variant="standard"
                        placeholder={t('messagePlaceholder')}
                        style = {{width: '100%'}}
                        value={message}
                        onChange={handleMessage}
                        onFocus={() => setOnWriting(true)}
                        onBlur={() => setOnWriting(false)}
                        helperText={onWriting ? t('messageLength') : ''}
                        inputProps={{ maxLength: 256 }}
                    />
                </div>
                <TextField
                    type={showPassword ? "text" : "password"}
                    label={t('giftListPassword')}
                    value={password}
                    onChange={handlePasswordChange}
                    variant="standard"
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <div className="AcceptTermsContainer">
                    <Checkbox
                        onChange={handleTermsAccepted}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        sx={{
                            color: 'black',
                            '&.Mui-checked': {
                                color: 'black',
                            },
                        }}
                    /> <span style={termsStyle}>{t('acceptTermsAndConditionsText')} <a href="/documents/Terms_Conditions.pdf" target="_blank">{t('termsAndConditions')}</a></span>
                </div>
                <div className="AcceptPrivacyContainer mb-5">
                    <Checkbox
                        onChange={handlePrivacyAccepted}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox'}}
                        sx={{
                            color: 'black',
                            '&.Mui-checked': {
                                color: 'black',
                            },
                        }}
                    /> <span style={privacyStyle}>{t('acceptPrivacyPolicyText')} <a href="/documents/Privacy_Policy.pdf" target="_blank" >{t('privacyPolicy')}</a></span>
                </div>
                <div className="ButtonContainer">
                    <button type="submit" className="Fgift-button" style={{width: '100%'}}>
                        {t('create_message')}
                    </button>
                </div>
            </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(CreateMessage);